import React from "react";

const ProjectsCard = ({ side, image, title, category, onClick, data }) => {
  return (
    <div class="flex flex-row select-none"
    >
      {
        side === "left" ?
          <>
            <div class="basis-1/2 m-2">
              <img
                className="w-full h-full object-cover scale-100 group-hover:scale-110 duration-300 cursor-pointer"
                src={image}
                alt="ImageOne"
              />
            </div>
            <div class="basis-1/2 m-2">
              <h3 className="font-titleFont text-lg font-semibold text-[#ccc]">
                {title}
              </h3>
              <p className="">
                {data?.des}
              </p>
              <div className="flex flex-row">
                {data.codsList.map((icon, index) =>
                  <div className="basis-2/2 mr-2 text-2xl" key={index}>{icon}</div>)}
              </div>
              
              {data?.ViewProject ?
                <a href={data?.ViewProject} className="text-blue-500 underline">
                  View Project
                </a> : ""
              } 
            </div>
          </> : <>
            <div class="basis-1/2 m-2">
              <h3 className="font-titleFont text-lg font-semibold text-[#ccc]">
                {title}
              </h3>
              <p className="">
                {data?.des}
              </p>
              <div className="flex flex-row">
                {data.codsList.map((icon, index) =>
                  <div className="basis-2/2 mr-2 text-2xl" key={index}>{icon}</div>)}
              </div>
              {data?.ViewProject ?
                <a href={data?.ViewProject} className="text-blue-500 underline">
                  View Project
                </a> : ""
              } 
            </div>
            <div class="basis-1/2 m-2">
              <img
                className="w-full h-full object-cover scale-100 group-hover:scale-110 duration-300 cursor-pointer"
                src={image}
                alt="ImageOne"
              />
            </div>
          </>
      }
    </div>
  );
};

export default ProjectsCard;
