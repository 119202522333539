import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { BsCloudLightningFill, BsFacebook } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SiYoutubemusic } from "react-icons/si";
import { FiInstagram, FiMail, FiSend } from "react-icons/fi";
import { FaWhatsappSquare } from "react-icons/fa";
import whatsappnoew from "../../assets/whatsappnoew.png"
// import CV from "";
import { bannerImg } from "../../assets/index";

const Left = ({ setContact }) => {
  const [text] = useTypewriter({
    words: ["Full-Stack Developer & Software Engineer", "Windows application developer", "Android application developer", "Web application developer"],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 20,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-5/12 h-full bg-bodyColor rounded-2xl shadow-testShwdow z-0">
      <div className="w-full h-3/5">
        <img
          className="w-full h-full object-cover rounded-2xl"
          src={bannerImg}
          loading="priority"
          alt="bannerImage"
        />
      </div>
      <div className="w-full h-2/5 flex flex-col justify-between border-t-0 rounded-bl-xl rounded-br-xl">
        <div className="flex flex-col items-center gap-2 py-10">
          <h1 className="text-textColor text-4xl font-semibold">Aminul Islam</h1>
          <p className="text-base text-designColor tracking-wide">
            {text}
            <Cursor cursorBlinking="false" cursorStyle="|" />
          </p>
          <div className="flex justify-center gap-2 mt-2">
            <a href="https://github.com/alsheikhaminulislam">
              <span className="hover:text-designColor duration-300 cursor-pointer text-2xl">
                <FaGithub />
              </span>
            </a>
            <a href="https://www.linkedin.com/in/alsheikhaminulislam/">
              <span className="hover:text-designColor duration-300 cursor-pointer text-2xl">
                <FaLinkedin />
              </span>
            </a>
            <a href="https://www.youtube.com/@alsheikhaminulislam">
              <span className="hover:text-designColor duration-300 cursor-pointer text-2xl">
                <SiYoutubemusic />
              </span>
            </a>
            <a href="https://facebook.com/alsheikhaminulislam1">
              <span className="hover:text-designColor duration-300 cursor-pointer text-2xl">
                <BsFacebook />
              </span>
            </a>
            <a href="https://www.instagram.com/alsheikhaminulislam/">
              <span className="hover:text-designColor duration-300 cursor-pointer text-2xl">
                <FiInstagram />
              </span>
            </a>
            <a href="mailto:alsheikhaminulislam@gmail.com">
              <span className="hover:text-designColor duration-300 cursor-pointer text-2xl">
                <FiMail />
              </span>
            </a>
          </div>

          {/* <a href="https://wa.me/+8801763686527">
            <span className="grid justify-items-center "> 
              <img alt="" src={whatsappnoew} className="w-3/6" />
            </span>
          </a> */}

        </div>
        <div className="flex h-14">
          <a
            // href={CV}
            target="_blank"
            className="w-1/2 border-t-[1px] borderRight border-t-zinc-800 text-sm tracking-wide uppercase  gap-2 hover:text-designColor duration-300"
            rel="noreferrer"
          >
            <button className="w-full h-full flex justify-center items-center gap-2">
              Download CV <BsCloudLightningFill />
            </button>
          </a>
          <button
            onClick={() => setContact(true)}
            className="w-1/2 border-t-[1px] border-t-zinc-800 text-sm tracking-wide uppercase flex justify-center items-center gap-2 hover:text-designColor duration-300">
            Contact me <FiSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Left;
