
import Title from "../home/Title";
import AboutMe from "./AboutMe";
import FunFact from "./FunFact";
import MyServices from "./MyServices";
import Skills from "../resume/Skills";
import { FaChevronDown } from "react-icons/fa";
import React, { useState } from "react";
import ResumeCard from "../resume/ResumeCard";
import {
  webmail,
  workImgThree,
  workImgFive,
  valoxity,
  lessonfortress,
} from "../../assets";
import ProjectsCard from "../projects/ProjectsCard";
import { FaReact, FaHtml5, FaJava, FaPhp } from 'react-icons/fa';
import { PiFileCssLight } from "react-icons/pi";
import { SiTailwindcss } from "react-icons/si";
import { TbBrandMysql } from "react-icons/tb";
import { IoLogoElectron } from "react-icons/io5";



const ResumeCardExperience = ({ badge, title, subTitle, des }) => {
  const [isArrow, setArrow] = useState(false);
  return (
    <div className="w-full py-2 flex flex-col gap-2.5 border-b-[1px] border-b-zinc-800">
      {badge === "" ? (
        <h6 className="w-28 text-center text-sm py-[1px] text-designColor border-[1px] border-designColor rounded-sm">
          2013-Present
        </h6>
      ) : (
        <h6 className="w-24 text-center text-sm py-[1px] text-[#999] border-[1px] border-zinc-600 rounded-sm">
          {badge}
        </h6>
      )}
      <div className="grid grid-cols-8">

        <h2 className="text-lg font-titleFont text-gray-200 font-medium  col-span-6">
          {title}
        </h2>
        <div className={`col-span-2 grid justify-items-center text-xl ${!isArrow ? "rotate-0" : "rotate-180"} h-1`} onClick={() => setArrow(!isArrow)} >
          {!isArrow && (<p className="text-xs impact-font">Read Story</p>)}
          <FaChevronDown />
        </div>
      </div>
      <p className="text-sm text-[#999] -mt-2">{subTitle}</p>
      {isArrow && (<p className="text-base text-[#999] font-medium ">{des}</p>)}
    </div>
  );
};

const Projects = ({setProjects}) => {
  const list1 = [
    {
      title: "Mobile Application",
      category: "Android",
      image: workImgFive,
      des: "Our application is built for Android using Java, offering a seamless and interactive experience for users to explore and interact with geographic data on mobile devices. Designed with performance and responsiveness in mind, it enables real-time location tracking and spatial data visualization.",
      codsList: [
        <FaReact />,
        <FaHtml5 />,
        <PiFileCssLight />,
        <FaJava />,
        <TbBrandMysql />,
        <FaPhp />,
      ],
      ViewProject: "https://play.google.com/store/apps/details?id=com.valoxity.primogen"
    }, {
      title: "ValoXity",
      category: "Website",
      image: valoxity,
      des: "ValoXity is a technology-driven business offering innovative solutions in web and mobile development, tailored to meet specific client needs. With a focus on delivering high-performance applications, ValoXity helps businesses grow by providing scalable, reliable tools for long-term success.",
      codsList: [
        <FaHtml5 />,
        <PiFileCssLight />,
        <SiTailwindcss />,
        <TbBrandMysql />,
        <FaPhp />,
      ],
      ViewProject: "https://valoxity.com/"
    },
    {
      title: "LessonFortress: Sell and Protect Your Courses on Web, Mobile, and Desktop",
      category: "Website",
      image: lessonfortress,
      des: "LessonFortress is an academic platform where users can sell courses, built using MySQL, PHP, HTML, CSS, and JavaScript. The site has been converted into a mobile app (Java) and desktop app (Electron.js) for enhanced course protection and accessibility.",
      codsList: [
        <FaHtml5 />,
        <PiFileCssLight />,
        <TbBrandMysql />,
        <FaPhp />,
        <IoLogoElectron />,
        <FaJava />,
      ],
      ViewProject: ""
    },
    {
      title: "Custom Webmail",
      category: "Website",
      image: webmail,
      des: "At ValoXity, we've developed a custom webmail service that offers a range of email solutions tailored to meet diverse business and personal communication needs. Our platform supports various types of email services, providing users with flexibility, enhanced security, and seamless integration for effective communication.",
      codsList: [
        <FaReact />,
        <SiTailwindcss />,
        <FaPhp />,
        <TbBrandMysql />
      ],
      ViewProject: ""
    },
    {
      title: "Interactive Map",
      category: "Website",
      image: workImgThree,
      des: "Our application is a web-based platform built with React.js that delivers an interactive mapping experience, allowing users to explore and interact with geographic data in real-time. Designed for ease of use, it is ideal for applications like location tracking and spatial data visualization.",
      codsList: [
        <FaReact />,
        <FaHtml5 />,
        <PiFileCssLight />,
        <SiTailwindcss />,
        <TbBrandMysql />,
        <FaPhp />,
      ],
      ViewProject: "https://valoxity.com/reactjs-projects/primogen"
    },
  ]
  return (
    <div>
      <Title title="" subTitle="Featured Project" />
      <div className="w-full grid grid-cols-1 gap-6 lgl:gap-10">
        <div className="px-6">
          {list1.map((data, index) =>
            <div key={index}>
              <ProjectsCard
                side={index % 2 === 0 ? "left" : "right"}
                title={data.title}
                category={data.category}
                image={data.image}
                data={data}
              />
            </div>)}
        </div>
      </div>
      <div className="w-full flex justify-center my-2">
        <button className="btn" onClick={()=>setProjects(true)}>More</button>
      </div>

    </div>
  );
};
const About = ({setProjects}) => {
  return (
    <section id="about" className="w-full">
      <Title title="About" subTitle="Me" />
      <AboutMe />
      <FunFact />
      <Title title="" subTitle="Experience" />
      <div className="col-span-9 md:col-span-4 mx-6">
        <ResumeCardExperience
          badge="2022"
          title="API Developer"
          subTitle=""
          des="After learning full-stack app and web development, I focused on custom API development. Although I have mid-level experience, I made many custom APIs for different platforms such as Discord, Twitch, and Telegram, and for my API-based service platform. This platform offers IP, Captcha, JWT, PHP Mailer, QR Code, and more custom API solutions."
        />
        <ResumeCardExperience
          badge="2021"
          title="Desktop Application Developer"
          subTitle=""
          des="I am highly proficient in Java, JavaScript, C#/C++, and Python, and have successfully started developing desktop applications. With a solid mid-level understanding, I have already created a desktop application for my LMS startup and plan to develop many more soon."
        />
        <ResumeCardExperience
          badge="2020"
          title="Full Stack Web Application Developer"
          subTitle=""
          des="After learning Android app development, I started learning how to create websites with WordPress. Then, I learned PHP and MySQL. After a year, I began learning the MERN stack for developing web applications. I have successfully made several web applications. "
        />
        <ResumeCardExperience
          badge="2018"
          title="Full Stack Android Application Developer"
          subTitle=""
          des="I started my professional journey by acquiring skills in Android app development through block-to-code platforms as a personal initiative. Subsequently, I progressed to mastering Java, Firebase, and Flutter. Throughout my career, I have successfully created several applications for clients and my startup in collaboration with my business partner."
        />
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <Title title="" subTitle="Education" />
      <div className="w-full grid grid-cols-9 px-6">
        <div className="col-span-9 md:col-span-4">
          <ResumeCard
            badge="2022 - 2024"
            title="Higher Secondary Certificate (HSC)"
            subTitle="Dhaka Board"
            des="Completed Higher Secondary Education with a focus on Science, furthering my understanding of computational thinking and programming."
          />
        </div>
        <div className="w-full h-full hidden lgl:flex justify-center items-center">
          <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
        </div>
        <div className="col-span-9 md:col-span-4">
          <ResumeCard
            badge="2020 - 2021"
            title="Secondary School Certificate (SSC)"
            subTitle="Dhaka Board"
            des="Graduated with a strong foundation in Science and Mathematics, which laid the groundwork for my journey in programming and software development."
          />
        </div>
      </div>
      <Title title="" subTitle="Skills" />
      <Skills />
      <Title title="" subTitle="Services" />
      <MyServices />
      <div className="sm:hidden xl:block">
        <Projects setProjects={setProjects}/>
      </div>
    </section>
  );
};

export default About;
