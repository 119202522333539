import React, { useState } from "react";

const ServicesCard = ({ icons, title, subTitle }) => {
  const [isHover, setisHover] = useState(false);
  return (
    <div className="py-6 px-6 flex flex-col items-center gap-2 borderRight borderBottom relative"
      onMouseEnter={() => setisHover(true)}
      onMouseLeave={() => setisHover(false)}
    > 
      <div className="flex flex-col items-center justify-center transition-all duration-300 ease-in-out h-full">
<span className="text-4xl text-designColor mb-2">{icons}</span>
<h2 className="font-titleFont text-lg font-semibold">{title}</h2>
</div>
      {isHover && ( <div className="bg-textColor rounded-lg py-2">
        <p className="text-base text-center text-zinc-400 px-6 text-bodyColor">{subTitle}</p>
      </div> )}
    </div>
  );
};

// const ServicesCard = ({ icons, title, subTitle }) => {
//   const [isHover, setisHover] = useState(false);

//   return (
//     <div
//       className="py-8 px-6 flex flex-col items-center gap-2 borderRight borderBottom relative"
//       onMouseEnter={() => setisHover(true)}
//       onMouseLeave={() => setisHover(false)}
//     >
//       <div
//         className={`flex flex-col items-center transition-all duration-300 ease-in-out ${isHover ? 'opacity-0 translate-y-6' : 'opacity-100 translate-y-0'}`}
//       >
//         <span className="text-4xl text-designColor mb-2">{icons}</span>
//         <h2 className="font-titleFont text-lg font-semibold">{title}</h2>
//       </div>
//       {isHover && (
//         <div className="absolute inset-0 flex items-center justify-center bg-[#f0f6fc71] p-6 transition-opacity duration-300 ease-in-out">
//           <p className="text-base text-center text-zinc-400 px-6 text-bodyColor">
//             {subTitle}
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };
export default ServicesCard;
