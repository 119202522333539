import {
  webmail,
  workImgOne,
  workImgTwo,
  workImgThree,
  workImgFive,
  valoxity,
  lessonfortress,
} from "../../assets";
import Title from "../home/Title";
import ProjectsCard from "./ProjectsCard"; 
import { FaReact, FaHtml5 , FaJava,FaPhp } from 'react-icons/fa';
import { PiFileCssLight } from "react-icons/pi"; 
import { SiTailwindcss } from "react-icons/si";
import { TbBrandMysql } from "react-icons/tb";
import { IoLogoElectron } from "react-icons/io5";
const Projects = () => {
  const list1 = [
    {
      title: "Mobile Application",
      category: "Android",
      image: workImgFive,
      des:"Our application is built for Android using Java, offering a seamless and interactive experience for users to explore and interact with geographic data on mobile devices. Designed with performance and responsiveness in mind, it enables real-time location tracking and spatial data visualization.",
      codsList: [
        <FaReact/>,
        <FaHtml5/>,
        <PiFileCssLight/>,
        <FaJava/>,
        <TbBrandMysql />,  
        <FaPhp />,
      ], 
      ViewProject: "https://play.google.com/store/apps/details?id=com.valoxity.primogen"
    },
    {
      title: "Custom Webmail",
      category: "Website",
      image: webmail,
      des:"At ValoXity, we've developed a custom webmail service that offers a range of email solutions tailored to meet diverse business and personal communication needs. Our platform supports various types of email services, providing users with flexibility, enhanced security, and seamless integration for effective communication.",
      codsList: [
        <FaReact/>,  
        <SiTailwindcss/>,  
        <FaPhp />,
        <TbBrandMysql />
      ], 
      ViewProject: ""
    },
    {
      title: "Interactive Map",
      category: "Website",
      image: workImgThree,
      des:"Our application is a web-based platform built with React.js that delivers an interactive mapping experience, allowing users to explore and interact with geographic data in real-time. Designed for ease of use, it is ideal for applications like location tracking and spatial data visualization.",
      codsList: [
        <FaReact/>,
        <FaHtml5/>,
        <PiFileCssLight/>,
        <SiTailwindcss/>,
        <TbBrandMysql />,  
        <FaPhp />,
      ], 
      ViewProject: "https://valoxity.com/reactjs-projects/primogen"
    },{
      title: "ValoXity",
      category: "Website",
      image: valoxity,
      des:"ValoXity is a technology-driven business offering innovative solutions in web and mobile development, tailored to meet specific client needs. With a focus on delivering high-performance applications, ValoXity helps businesses grow by providing scalable, reliable tools for long-term success.",
      codsList: [ 
        <FaHtml5/>,
        <PiFileCssLight/>,
        <SiTailwindcss/>,
        <TbBrandMysql />,  
        <FaPhp />,
      ], 
      ViewProject: "https://valoxity.com/"
    },
    {
      title: "Mobile Application",
      category: "Android",
      image: workImgOne,
      des:"Our application is built for Android using Java, offering a seamless and interactive experience for users to explore and interact with geographic data on mobile devices. Designed with performance and responsiveness in mind, it enables real-time location tracking and spatial data visualization.",
      codsList: [
        <FaReact/>,
        <FaHtml5/>,
        <PiFileCssLight/>,
        <FaJava/>,
        <TbBrandMysql />,  
        <FaPhp />,
      ], 
      ViewProject: "https://play.google.com/store/apps/details?id=com.valoxity.echopulse"
    },
    {
      title: "LessonFortress: Sell and Protect Your Courses on Web, Mobile, and Desktop",
      category: "Website",
      image: lessonfortress,
      des:"LessonFortress is an academic platform where users can sell courses, built using MySQL, PHP, HTML, CSS, and JavaScript. The site has been converted into a mobile app (Java) and desktop app (Electron.js) for enhanced course protection and accessibility.",
      codsList: [ 
        <FaHtml5/>,
        <PiFileCssLight/>, 
        <TbBrandMysql />,  
        <FaPhp />,
        <IoLogoElectron/>,
        <FaJava/>,
      ], 
      ViewProject: "" 
    },
    {
      title: "Interactive Map",
      category: "Website",
      image: workImgTwo,
      des:"Our application is a web-based platform built with React.js that delivers an interactive mapping experience, allowing users to explore and interact with geographic data in real-time. Designed for ease of use, it is ideal for applications like location tracking and spatial data visualization.",
      codsList: [
        <FaReact/>,
        <FaHtml5/>,
        <PiFileCssLight/>,
        <SiTailwindcss/>,
        <TbBrandMysql />,  
        <FaPhp />,
      ], 
      ViewProject: "https://valoxity.com/reactjs-projects/echopulse" 
    },
  ]
  

  return (
    <>
      <div>
        <Title title="Recent" subTitle="Projects" />
        <div className="w-full grid grid-cols-1 gap-6 lgl:gap-10">
          <div className="px-6">
            {list1.map((data, index) =>
              <div key={index}>
                <ProjectsCard
                  side={index % 2 === 0?"left":"right"}
                  title={data.title}
                  category={data.category}
                  image={data.image} 
                  data={data}
                />
              </div>)}
          </div>
        </div>
      </div>  
    </>
  );
};

export default Projects;
