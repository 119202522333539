import React from "react";

const AboutMe = () => {
  return (
    <div className="flex flex-col md:flex-row pb-6">
      <div className="w-full md:w-full text-zinc-400 px-6 border-r-[1px] border-r-zinc-800 flex items-center">
        <div className="py-6">
          <h2 className="font-semibold mb-1">Assalamu Alaykum!  </h2>
          <p className="text-base leading-6 "> 
            I'm Aminul Islam from Dhaka, Bangladesh. With over 6 years of experience as a Full Stack Developer and Programmer. My expertise in creating dynamic Android, Windows, and Web Applications. I am skilled in using frameworks such as React, Node.js, and Tailwind CSS and proficient in WordPress.
          </p>
        </div>
      </div>
      {/* <div className="w-full md:w-1/2 p-6">
        <ul className="flex flex-col gap-1">
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Age:</span>
            21
          </li>
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Residence:</span>
            BD
          </li>
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Freelance:</span>
            Available
          </li>
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Address:</span>
            Savar, Dhaka
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default AboutMe;
