import React from "react";
import { FaAppStoreIos } from "react-icons/fa";
import { AiTwotoneAppstore } from "react-icons/ai";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import ServicesCard from "./ServicesCard";

const MyServices = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <ServicesCard
        icons={<BiCodeAlt />}
        title="Web Development"
        subTitle="I specialize in creating dynamic and responsive websites that are tailored to meet your specific business needs. Whether it's a complex web application or a simple landing page, I focus on delivering high-quality, user-friendly solutions that provide an exceptional online experience."
      />
      <ServicesCard
        icons={<SiAntdesign />}
        title="Desktop Application Development"
        subTitle="I offer custom desktop application development services for Windows platforms. Whether you need a simple utility tool or a complex enterprise solution, I create powerful and reliable software that enhances productivity and meets your specific requirements."
      />
      <ServicesCard
        icons={<AiTwotoneAppstore />}
        title="Mobile Application Development"
        subTitle="I develop custom mobile applications for Android platforms, ensuring they are intuitive, functional, and aligned with your business goals. From initial concept to deployment, I create apps that offer a seamless user experience and help your business reach its full potential on mobile devices."
      />
      <ServicesCard
        icons={<FaAppStoreIos />}
        title="API Development & Integration"
        subTitle="I specialize in developing robust and scalable APIs that allow seamless communication between different software systems. Whether you need a custom API for your application or integration with third-party services, I ensure secure and efficient data exchange to support your business operations."
      />
    </div>
  );
};

export default MyServices;
