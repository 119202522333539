import React from "react";
import { BsTrophyFill } from "react-icons/bs";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi"; 
import FunFactCard from "./FunFactCard";
import { RiUserSmileLine } from "react-icons/ri";
const FunFact = () => {
  return (
    <div className="px-0 grid grid-cols-2 md:grid-cols-4 pb-10">
      <FunFactCard icon={<BsTrophyFill />} des="6+ Years Experience " />
      <FunFactCard icon={<SiAntdesign />} des="50+ Finished Projects" />
      <FunFactCard icon={<BiCodeAlt />} des="2000+ hours of coding" />
      <FunFactCard icon={<RiUserSmileLine />} des="10k+ Users work impacts" />
    </div>
  );
};

export default FunFact;
